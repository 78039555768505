@media (max-width: 768px) {
  body{
    background-color: transparent;
  }
  #home .swiper-container {
    height: calc(100% - 2em) !important;
  }

  .project-nav div:last-of-type{
    display: block;
  }
}

#home{
  background-color: white;
}


.swiper-container picture, .swiper-container img{
  display: block;
  height: 100%;
  width: 100%;
}

.swiper-slide{
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
